import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import DataSafetyNet from "../images/DataSafetyNet.png"
import DynamicContent from "../images/DynamicContentUpdates.png"
import FastFlawless from "../images/FastFlawless.png"
import Macroair from "../images/Macroair-1.svg"
import StayUpdatedSecure from "../images/StayUpdatedSecure.png"
import TopoftheSearch from "../images/TopoftheSearchResults.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import Shapefour from "../images/bannerElement/white-design-element-webmaster.png"
import AlwaysAhead from "../images/icons/WebMaster/AlwaysAhead.svg"
import FutureProofTechnology from "../images/icons/WebMaster/Future-Proof-Technology.svg"
import IntuitiveSearch from "../images/icons/WebMaster/IntuitiveSearch.svg"
import PeaceofMind from "../images/icons/WebMaster/Peace-of-Mind.svg"
import ProactiveReactive from "../images/icons/WebMaster/ProactiveReactive.svg"
import SmartSEOInsights from "../images/icons/WebMaster/SmartSEOInsights.svg"
import TechExperts from "../images/icons/WebMaster/TechExperts.svg"
import UnmatchedUptime from "../images/icons/WebMaster/UnmatchedUptime.svg"
import Englert from "../images/logos/WMS/Group10.svg"
import Thales from "../images/logos/WMS/Group11.svg"
import Google from "../images/logos/WMS/Group12.svg"
import TDK from "../images/logos/WMS/Group13.svg"
import LeafGaurd from "../images/logos/WMS/Group15.svg"
import "../styles/404.css"

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  )
}
const WebMasterServices = ({ siteTitle }) => {
  useEffect(() => {
    const items = document.querySelectorAll(".accordion button")

    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded")

      if (itemToggle === "false") {
        for (let i = 0; i < items.length; i++) {
          items[i].setAttribute("aria-expanded", "false")
        }
        this.setAttribute("aria-expanded", "true")
      } else {
        // Check if the clicked accordion is already open, if yes, do nothing
        if (itemToggle === "true") {
          return
        }
        this.setAttribute("aria-expanded", "false")
      }
    }

    items.forEach(item => item.addEventListener("click", toggleAccordion))

    return () => {
      items.forEach(item => item.removeEventListener("click", toggleAccordion))
    }
  }, [])
  const [imageSrc, setImageSrc] = useState(StayUpdatedSecure)
  const [isLoading, setIsLoading] = useState(false)

  const changeImage = newImageSrc => {
    setIsLoading(true)
    setTimeout(() => {
      setImageSrc(newImageSrc)
      setIsLoading(false)
    }, 50)
  }

  useEffect(() => {
    setIsLoading(true)
    const image = new Image()
    image.src = imageSrc
    image.onload = () => {
      setIsLoading(false)
    }
  }, [imageSrc])

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Webmaster Services</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="web-master-services content-creative">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>Keep your website thriving</h1>
                    <p>Stress-free Webmaster services with AI power</p>
                    <a className="button" href="/contact-us">
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>Web experts dedicated to your growth</h2>
              <p>
                Your website is the welcoming face of your business online. It's
                where you greet potential customers, showcase your work, and
                build trust. But keeping it running smoothly and securely is a
                constant balancing act. That's where we come in. Our expert
                Webmaster team handles your website's technical side through
                updates, security checks, speed optimizations, and top SEO
                rankings powered by AI while you can focus on business growth.
              </p>
            </div>
          </section>
          <section id="TextImageSection" class="web-master-services">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Here’s what sets us apart</h2>
                  <div class="accordion">
                    <div class="accordion-item">
                      <button
                        id="accordion-button-1"
                        aria-expanded="true"
                        onClick={() => changeImage(StayUpdatedSecure)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">
                          Stay Updated &amp; Secure
                        </span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We handle software updates and patches across popular
                          platforms like WordPress, Shopify, and WooCommerce,
                          keeping your site secure and protected.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-2"
                        aria-expanded="false"
                        onClick={() => changeImage(DynamicContent)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">
                          Dynamic Content Updates
                        </span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We help you keep your website engaging with fresh,
                          relevant content that attracts and retains visitors.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-3"
                        aria-expanded="false"
                        onClick={() => changeImage(FastFlawless)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">Fast &amp; Flawless</span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We optimize your website for speed, ensuring a smooth
                          user experience and happy customers.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-4"
                        aria-expanded="false"
                        onClick={() => changeImage(TopoftheSearch)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">
                          Top of the Search Results
                        </span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We manage your SEO, ensuring your website ranks high
                          in search results and gets seen by the right audience.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-5"
                        aria-expanded="false"
                        onClick={() => changeImage(DataSafetyNet)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">Data Safety Net</span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We back up your website regularly, so you're never
                          worried about losing valuable information.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="img-wrap">
                  {isLoading && <Loader />}
                  <div
                    className={`image-container ${isLoading ? "fade-in" : ""}`}
                  >
                    <img
                      alt="Copy write"
                      width="500"
                      height="500"
                      src={imageSrc}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="IconColSection">
            <div class="container">
              <div class="inner-wrapper">
                <h2>How we leverage AI to power up your website</h2>
                <div class="icon-cardwrapper">
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img
                        alt="Unmatched Uptime"
                        width="100"
                        height="100"
                        src={UnmatchedUptime}
                      />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <span>
                          Unmatched Uptime <br />
                        </span>
                        AI-powered monitoring guarantees 99.99% uptime, keeping
                        your website accessible and users happy.
                      </p>
                    </div>
                  </div>
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img
                        alt="Smart SEO Insights"
                        width="100"
                        height="100"
                        src={SmartSEOInsights}
                      />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <span>
                          Smart SEO Insights <br />
                        </span>
                        AI generates data-driven keyword strategies, pushing
                        your site to the top of search rankings.
                      </p>
                    </div>
                  </div>
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img
                        alt="Future-Proof Technology"
                        width="100"
                        height="100"
                        src={FutureProofTechnology}
                      />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <span>
                          Future-proof Technology
                          <br />
                        </span>
                        Our team uses AI to analyze trends and keep your website
                        technology ahead of the curve.
                      </p>
                    </div>
                  </div>
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img
                        alt="Intuitive Search"
                        width="100"
                        height="100"
                        src={IntuitiveSearch}
                      />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <span>
                          Intuitive Search <br />
                        </span>
                        AI-enhanced search functionalities offer precise,
                        relevant, and dynamically improving results for your
                        users.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="ImageDescColSection">
            <div class="container">
              <h2>Why choose us?</h2>
              <div class="imgdesc-cardwrapper">
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Proactive"
                      width="100"
                      height="100"
                      src={ProactiveReactive}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>
                      <span>
                        Proactive, not Reactive <br />
                      </span>
                      We address issues before they affect you.
                    </p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Tech Experts"
                      width="100"
                      height="100"
                      src={TechExperts}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>
                      <span>
                        Tech Experts, not Jargon <br />
                      </span>
                      We explain things clearly, <br />
                      no jargon.
                    </p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Ahead of Curve"
                      width="100"
                      height="100"
                      src={AlwaysAhead}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>
                      <span>
                        Always Ahead of the Curve <br />
                      </span>
                      We stay updated on the <br />
                      latest trends.
                    </p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Peace of Mind"
                      width="100"
                      height="100"
                      src={PeaceofMind}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>
                      <span>
                        Peace of Mind Guarantee <br />
                      </span>
                      24/7 support and <br />
                      99.99% uptime.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PartnerLogoSection">
            <div class="container">
              <div class="logo-wrap">
                <div class="each-logo">
                  <img src={Englert} alt="Englert" />
                </div>
                <div class="each-logo">
                  <img src={Thales} alt="Thales" />
                </div>
                <div class="each-logo">
                  <img src={Google} alt="Google" />
                </div>
                <div class="each-logo">
                  <img src={TDK} alt="TDK" />
                </div>
                <div class="each-logo">
                  <img src={Macroair} alt="Macroair" />
                </div>
                <div class="each-logo">
                  <img src={LeafGaurd} alt="LeafGaurd" />
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>Ready for a website that thrives, not just survives?</h2>
              <a className="button" href="/contact-us">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default WebMasterServices
